import request from '@/utils/requestv2'
import { stringify } from 'qs'

// 帮助分类列表
export async function list(data) {
  return request({
    url: '/admin/help/category/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 帮助分类新增
export async function add(data) {
  return request({
    url: '/admin/help/category/add',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 帮助分类删除
export async function deleteBatch(data) {
  return request({
    url: '/admin/help/category/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 帮助分类修改
export function update(data) {
  return request({
    url: '/admin/help/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}
