import request from '@/utils/request'
import { stringify } from 'qs'

// 菜单列表(非分页)
export function menuList(data) {
  return request({
    url: '/admin/sys/menu/list',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 菜单树
export function menuListTree(data) {
  return request({
    url: '/admin/sys/menu/tree',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 新增菜单
export function menuSave(data) {
  return request({
    url: '/admin/sys/menu/save',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 编辑菜单
export function menuUpdate(data) {
  return request({
    url: '/admin/sys/menu/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 删除菜单
export function menuDelete(data) {
  return request({
    url: '/admin/sys/menu/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 排序菜单
export function menuSort(data) {
  return request({
    url: '/admin/sys/menu/sort',
    method: 'post',
    data: stringify({ ...data }),
  })
}
