import request from '@/utils/request'
import { stringify } from 'qs'

// 字典类型列表（分页）
export async function dictionaryList(data) {
  return request({
    url: '/admin/sys/dict/typePage',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 字典值型列表（分页）（根据字典类编码展开）
export async function dictionaryList_sn(data) {
  return request({
    url: '/admin/sys/dict/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 字典类型新增(左边的)
export async function dictionaryList_typeSave(data) {
  return request({
    url: '/admin/sys/dict/typeSave',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 字典类型编辑(左边的)
export async function dictionaryList_typeUpdate(data) {
  return request({
    url: '/admin/sys/dict/typeUpdate',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 字典类型删除(左边的)
export async function dictionaryList_typeDelete(data) {
  return request({
    url: '/admin/sys/dict/typeDelete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 字典值新增(右边的)
export async function dictionaryList_valueSave(data) {
  return request({
    url: '/admin/sys/dict/save',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 字典值删除(右边的)
export async function dictionaryList_valueDelete(data) {
  return request({
    url: '/admin/sys/dict/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 字典值编辑(右边的)
export async function dictionaryList_valueUpdate(data) {
  return request({
    url: '/admin/sys/dict/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}
