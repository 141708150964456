import request from '@/utils/request'
import { stringify } from 'qs'

export function roleList(data) {
  return request({
    url: '/admin/sys/role/page',
    method: 'post',
    data: stringify({ ...data }),
  })
}
export function querySysRoleList() {
  return request({
    url: '/admin/sys/role/querySysRoleList',
    method: 'post',
  })
}

// 新增角色
export function addRole(data) {
  return request({
    url: '/admin/sys/role/save',
    method: 'post',
    data: stringify({ ...data }),
  })
}

export function editRole(data) {
  return request({
    url: '/admin/sys/role/update',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 删除角色
export function deleteRole(data) {
  return request({
    url: '/admin/sys/role/delete',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 获取角色菜单(菜单回显)
export function getMenuByRole(data) {
  return request({
    url: '/admin/sys/role/getMenu',
    method: 'post',
    data: stringify({ ...data }),
  })
}

// 设置角色菜单
export function saveRoleMenu(data) {
  return request({
    url: '/admin/sys/role/saveMenu',
    method: 'post',
    data: stringify({ ...data }),
  })
}
