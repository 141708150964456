var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "vab-avatar-list" },
    _vm._l(_vm.avatarList, function (item, index) {
      return _c(
        "el-tooltip",
        {
          key: index,
          attrs: {
            content: item.username,
            effect: "dark",
            placement: "top-start",
          },
        },
        [_c("el-avatar", { attrs: { src: item.avatar } })],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }